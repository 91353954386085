/* 
 * @Author: 曹俊杰 
 * @Date: 2022-02-22 18:21:16
 * @Module: 添加电子文书
 */
 <template>
  <div class="electronicDocuments">
    <e-header :step="1" @next="submitForm" />
    <div class="content">
      <hdq-section title="合同基本信息" moreText="" />
      <div style="padding:0 24px;">
        <el-upload accept=".pdf,.docx,.doc,.wps" :limit="1" :headers="{
					Authorization: 'Bearer ' + user.token
				}" :on-success="onSuccess" action="/api/file" :on-preview="onPreview" :on-exceed="onExceed" :before-upload="beforeUpload" :before-remove="beforeRemove" :on-error="onError" multiple>

          <div class="upload">
            <i class="el-icon-upload2"></i>
            点击上传文档
          </div>
        </el-upload>

        <el-form label-suffix="：" :model="form" ref="form" style="margin-top:15px">
          <el-form-item label="合同主题" prop="subject" :rules="{ required: true, message: '请输入合同主题名称' }">
            <el-input v-model.trim="form.subject" size="mini" placeholder="请输入合同主题名称" style="width:322px"></el-input>
          </el-form-item>
        </el-form>
      </div>

    </div>
    <div class="content" style="margin-top:16px;">
      <hdq-section title="设置签署方" moreText="" />
      <div class="flow">
        <div class="row" v-if="signersList.length">
          <div class="col1">签署</div>
          <div class="col2">
            <span style="margin-left:10%;">参与方信息</span>
          </div>
        </div>
        <div v-for="(item, index) in signersList" :key="item.id">
          <el-form :model="item" :ref="'form' + item.id">

            <div class="row PERSON" v-if="item.type === 'PERSON'">
              <div class="col1">{{ index + 1 }}</div>
              <div class="col2 ">
                <div class="l">个人</div>
                <div class="PERSON-item">
                  <el-form-item prop="operatorName" :rules="{ required: true, message: '个人姓名不能为空' }">
                    <el-autocomplete popper-class="autocomplete" style="width:100%" value-key="operatorName" v-model.trim="item.operatorName" size="mini" :fetch-suggestions="PERSONSearch" placeholder="个人姓名" trigger-on-focus @select="res => handleSelect(res, index)">
                    </el-autocomplete>
                  </el-form-item>
                </div>
                <div class="PERSON-item">
                  <el-form-item prop="operatorPhone" :rules="phoneRules">
                    <el-input v-model.trim="item.operatorPhone" size="mini" placeholder="个人手机号"></el-input>
                  </el-form-item>
                </div>
                <div class="PERSON-item">
                  <el-form-item prop="operatorIdNumber" :rules="idCardRules">
                    <el-input v-model.trim="item.operatorIdNumber" size="mini" placeholder="个人身份证号"></el-input>
                  </el-form-item>
                </div>
                <div class="el-icon-delete r" @click="() => remove(item)"></div>
              </div>
            </div>

            <div class="row ORG" v-if="item.type === 'ORG'">
              <div class="col1">{{ index + 1 }}</div>
              <div class="col2 ">
                <div class="l">企业</div>
                <div class="c">
                  <div class="t">
                    <div class="ORG-item">
                      <el-form-item prop="name" :rules="{ required: true, message: '企业名称不能为空' }">
                        <el-autocomplete disabled popper-class="autocomplete" style="width:100%" v-model="item.name" size="mini" :fetch-suggestions="ORGSearch" placeholder="企业名称" trigger-on-focus @select="res => handleSelect(res, index)">
                          <template slot-scope="{ item }">
                            <div class="name">{{ item.name }}</div>
                            <span class="addr">{{ item.operatorName }}</span>
                          </template>
                        </el-autocomplete>
                      </el-form-item>
                    </div>
                    <div class="ORG-item">
                      <el-form-item prop="idNumber" :rules="{ required: true, message: '社会信用代码不能为空' }">
                        <el-input disabled v-model="item.idNumber" size="mini" placeholder="社会信用代码"></el-input>
                      </el-form-item>
                    </div>

                  </div>
                  <div class="b">
                    <div class="ORG-item">
                      <el-form-item prop="operatorName" :rules="{ required: true, message: '合同操作人不能为空' }" error="选择谁，则谁负责扫码签约">
                        <!-- <el-input v-model="item.operatorName" size="mini" placeholder="签署人姓名"></el-input> -->

                        <el-select @change="(res,index)=>ORGchange(res,item)" v-model="item.operatorName" size="mini" style="width:100%" placeholder="请选择合同操作人">
                          <el-option v-for="item in accountColleagueList" :key="item.uuid" :label="item.name" :value="item.uuid">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="ORG-item">
                      <el-form-item prop="operatorPhone" :rules="{ required: true, message: '合同操作人手机号不能为空' }">
                        <el-input disabled v-model="item.operatorPhone" size="mini" placeholder="合同操作人手机号"></el-input>
                      </el-form-item>
                    </div>
                    <div class="ORG-item">
                      <el-form-item prop="operatorIdNumber" :rules="{ required: true, message: '合同操作人身份证号不能为空' }">
                        <el-input disabled v-model="item.operatorIdNumber" size="mini" placeholder="合同操作人身份证号"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <div class="el-icon-delete r" @click="() => remove(item)"></div>
              </div>
            </div>
          </el-form>
        </div>
        <div style="padding-left:72px;">
          <el-button type="primary" plain size="small" @click="() => add('ORG')">添加企业</el-button>
          <el-button type="warning" plain size="small" @click="() => add('PERSON')">添加个人</el-button>
        </div>
      </div>
    </div>

  </div>
</template>
 <script>
import { mapState } from "vuex";
import eHeader from "./components/eHeader.vue"
export default {
  components: {
    eHeader
  },
  data () {
    const phoneValidator = (_, value, callback) => {
      if (this.$utils.test.mobile(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的手机号'));
      }
    }
    const idCardValidator = (_, value, callback) => {
      if (this.$utils.test.idCard(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的身份证号'));
      }
    }
    return {
      phoneRules: [{ required: true, message: '个人手机号不能为空' }, { validator: phoneValidator, trigger: 'blur' }],
      idCardRules: [{ required: true, message: '个人身份证号不能为空' }, { validator: idCardValidator, trigger: 'blur' }],
      fileList: [],
      form: {
        subject: '',
        files: []
      },
      signersList: [

      ],
      accountColleagueList: [],
    };
  },
  mounted () {

    this.getAccountColleagueList(data => {
      // const item = data[0]
      this.signersList.push({
        id: this.$utils.guid(),
        type: 'ORG',//PERSON 个人,ORG 企业
        name: this.user.company,
        idNumber: this.user.companyIdNumber,
        operatorName: '',
        operatorPhone: '',
        operatorIdNumber: ''
      })
    })
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      personalSearch: state => state.electronic.personalSearch,
      enterpriseSearch: state => state.electronic.enterpriseSearch
    })
  },
  methods: {
    ORGSearch (queryString, cb) {
      var restaurants = this.enterpriseSearch;
      var results = queryString ? restaurants.filter(item => item.name.includes(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    handleSelect (item, index) {
      const id = this.signersList[index].id
      this.signersList[index] = { ...item, id }
    },
    PERSONSearch (queryString, cb) {
      var restaurants = this.personalSearch;
      var results = queryString ? restaurants.filter(item => item.operatorName.includes(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },

    submitForm () {
      if (this.signersList.length < 2) {
        this.$message({
          message: '至少添加两个任务参与人',
          type: 'error'
        })
        return
      }

      const arr = this.signersList.map(res => {
        if (res.type == "ORG" && (res.operatorName.length > 10)) {
          res.operatorName = this.accountColleagueList[this.accountColleagueList.findIndex(r => r.uuid == res.operatorName)].name;
        }
        return this.$refs['form' + res.id][0].validate();
      })
      arr.push(this.$refs.form.validate())
      Promise.all(arr).then((values) => {
        if (values.findIndex(target => target === false) == -1) {
          if (this.form.files.length) {
            this.$router.push({ name: 'electronicDocumentsPDF' })
            this.$store.commit('electronic/setElectronic', {
              subject: this.form.subject,
              files: this.form.files,
              signers: this.signersList,
            })
            this.$store.commit('electronic/saveHistory', this.signersList)
          } else {
            this.$message({
              type: 'error',
              message: '请上传合同文件'
            })
          }
        } else {
          alert('error!');
        }
      });
    },
    add (res) {
      const id = this.$utils.guid()
      if (res === 'PERSON') {
        this.signersList.push(
          {
            id,
            type: res,//PERSON 个人,ORG 企业
            operatorName: '',
            operatorPhone: '',
            operatorIdNumber: '',
          }
        )
      } else if (res === 'ORG') {
        const item = this.accountColleagueList[0]
        this.signersList.push(
          {
            id,
            type: res,//PERSON 个人,ORG 企业
            // name: '',
            // idNumber: '',
            // operatorName: '',
            // operatorPhone: '',
            // operatorIdNumber: '',
            name: this.user.company,
            idNumber: this.user.companyIdNumber,
            operatorName: '',
            operatorPhone: '',
            operatorIdNumber: ''
          }
        )
      }
    },
    //文件超出个数限制时的钩子
    onExceed (files, fileList) {
      this.$message({
        message: '只可同时签订一份合同',
        type: 'error'
      })
    },
    //文件上传成功时的钩子
    onSuccess (response) {
      const { status, info, data } = response;
      if (status == 200) {
        this.form.files = [{
          fileId: data.fileId,
          fileName: data.name
        }]
        this.$store.commit('electronic/setElectronic', {
          file: data.downloadUrl
        })
      } else {

        this.$message({
          type: 'error',
          message: info
        })
      }
    },
    remove ({ id }) {
      const i = this.signersList.findIndex(res => res.id === id)
      if (i > -1) {
        this.signersList.splice(i, 1)
      }
    },
    // 点击已上传成功文件
    onPreview (file) {
    },
    beforeRemove (file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    onError () {
      console.log('onError')
    },
    // 文件上传前
    beforeUpload ({ size }) {
      const MB = 40
      const maxSize = MB * 1024 * 1024
      if (size > maxSize) {
        this.$message({
          message: `文件大小不能超过${MB}MB`,
          type: 'error'
        })
        return false
      }
    },
    async getAccountColleagueList (callback) {
      const { status, data } = await this.$api.getAccountColleagueList({ withSealAuth: true })
      if (status === 200) {
        this.accountColleagueList = data;
        callback && callback(data)
      }
    },
    ORGchange (res, item) {
      const user = this.accountColleagueList[this.accountColleagueList.findIndex(r => r.uuid == res)];
      item.operatorIdNumber = user.idNumber;
      item.operatorPhone = user.phone;
    }
  }
};
</script>
 <style lang='scss' scoped>
.electronicDocuments {
  .content {
    background: #fff;
    padding-bottom: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
  }

  &/deep/.el-card {
    &__body {
      padding: 0px;
    }
  }
}

.autocomplete {
  li {
    line-height: normal;
    padding: 4px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}

.upload {
  width: 416px;
  background: #f5f9ff;
  border-radius: 4px;
  border: 1px dashed #66a1ff;

  font-size: 14px;
  color: #0062ff;
  line-height: 64px;
}

.flow {
  padding: 0 24px;

  .row {
    display: flex;
    font-size: 12px;
    color: #666666;

    .col1 {
      width: 60px;
      background: #f7f8f9;
      border-radius: 4px;
      // line-height: 40px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .col2 {
      background: #f7f8f9;
      border-radius: 4px;
      line-height: 40px;
      flex: 1;
      margin-left: 12px;
      margin-bottom: 12px;
      align-items: center;
    }

    .l {
      width: 112px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .r {
      width: 66px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .PERSON {
    .col1 {
    }

    .col2 {
      display: flex;
      background: #fff;
      box-shadow: 0px 4px 6px 0px #f5f9ff;
      border-radius: 4px;
      border: 1px solid #e3e3e3;
      height: 100px;

      .l {
        color: #ef7d00;
      }

      .PERSON-item {
        padding-top: 22px;
        width: calc((100% - 66px - 112px - (20px * 2)) / 3);
        margin-right: 20px;

        &:nth-child(4n) {
          margin-right: 0;
        }
      }

      .r {
        font-size: 16px;
        color: #999999;

        &:hover {
          color: #e05353;
        }
      }
    }
  }

  .ORG {
    .col1 {
    }

    .col2 {
      display: flex;
      background: #fff;
      box-shadow: 0px 4px 6px 0px #f5f9ff;
      border-radius: 4px;
      border: 1px solid #e3e3e3;

      .l {
        color: #0062ff;
      }

      .c {
        width: calc(100% - 66px - 112px);
        padding-top: 27px;
        padding-bottom: 5px;

        .b {
          width: 100%;
          display: flex;

          .ORG-item {
            // padding-top: 22px;
            width: calc((100% - (20px * 2)) / 3);
            margin-right: 20px;

            &:nth-child(3n) {
              margin-right: 0;
            }
          }
        }

        .t {
          display: flex;

          // margin-top: 12px;
          .ORG-item {
            // padding-top: 22px;
            width: calc((100% - (20px * 2)) / 3);
            margin-right: 20px;

            &:nth-child(2n) {
              margin-right: 0;
            }

            &:last-child {
              width: calc(((100% - (20px * 2)) / 3) * 2 + 20px);
            }
          }
        }
      }

      .r {
        font-size: 16px;
        color: #999999;

        &:hover {
          color: #e05353;
        }
      }
    }
  }
}
</style>